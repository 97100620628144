.btn-pesquisa {
  color: #ffffff !important;
  border-color: #4CA64C !important;
  background-color: #4CA64C !important;
}

.btn-pesquisa:hover {
  color: #ffffff !important;
  border-color: #0a6b25 !important;
  background-color: #0a6b25 !important;
}

.btn-light-pesquisa {
  color: #ffffff !important;
  border-color: #12381d !important;
  background-color: #12381d !important;
}

.btn-light-pesquisa:hover {
  color: #ffffff !important;
  border-color: rgb(2, 20, 7)  !important;
  background-color: rgb(2, 20, 7)  !important
}

.bg-pesquisa{
  background-color: #4CA64C !important;
}

[data-bs-theme="light"] .bg-light-pesquisa{
  background-color: #9DCE9D !important;
}

[data-bs-theme="dark"] .bg-light-pesquisa{
  background-color: #1c412c !important;
}

.border-pesquisa{
  border-color: #4CA64C !important;
}

.titulo-pesquisa-selecionado{
  border: 1px solid #3aa133 !important;
}

.titulo-pesquisa-interativo.titulo-pesquisa-nao-selecionado .card-header{
  cursor: pointer !important;
  background-color: #67B367 !important;
}

.titulo-pesquisa-nao-selecionado h3 {
  opacity: 70% !important;
}

.texto-pesquisa{
  opacity: 0.8 !important;
}

[data-bs-theme="light"] .texto-pesquisa{
  background-color: #D2E9D2!important;
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .texto-pesquisa{
  background-color: rgb(30, 70, 43) !important;
  color: #e4e4e4 !important;
}

.texto-pesquisa-selecionada{
  opacity: 1 !important;
  border: 1px solid #37a133 !important;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}

[data-bs-theme="light"] .texto-pesquisa-selecionada{
  background-color: #9DCE9D !important; 
}

[data-bs-theme="dark"] .texto-pesquisa-selecionada{
  background-color: #53715f !important;
}

.texto-pesquisa-nao-selecionada{
  cursor: pointer !important;
}

.painel-pesquisa-mobile{
  top: calc(50% - 204.19px) !important;
  left: calc(100% - 45.3px) !important;
}

.painel-pesquisa-desktop .painel-pesquisa-action {
  border-radius: 10px !important;
  border: 4px solid #4CA64C !important;
}

[data-bs-theme="dark"] .painel-pesquisa-desktop .painel-pesquisa-botao-ia {
  border: 4px solid #4CA64C !important;
}

[data-bs-theme="dark"] .painel-pesquisa-desktop .border-painel-pesquisa-left {
  background-color: #4f9165 !important;
  border-top-left-radius: 15% !important;
  border-top: 1px solid #4CA64C !important;
  border-bottom-left-radius: 15% !important;
  border-bottom: 1px solid #4CA64C !important;
}

[data-bs-theme="dark"] .painel-pesquisa-desktop .border-painel-pesquisa-top {
  background-color: #4f9165 !important;
  border-top: 1px solid #4CA64C !important;
  border-bottom: 1px solid #4CA64C !important;
}

[data-bs-theme="dark"] .painel-pesquisa-desktop .border-painel-pesquisa-right {
  background-color: #4f9165 !important;
  border-top-right-radius: 15% !important;
  border-top: 1px solid #4CA64C !important;
  border-right: 1px solid #4CA64C !important;
  border-bottom-right-radius: 15% !important;
  border-bottom: 1px solid #4CA64C !important;
}

[data-bs-theme="light"] .painel-pesquisa-desktop .painel-pesquisa-botao-ia {
  border: 4px solid #4CA64C !important;
}

[data-bs-theme="light"] .painel-pesquisa-desktop .border-painel-pesquisa-left {
  background-color: #CFF7DC !important; 
  border-top-left-radius: 15% !important;
  border-top: 1px solid #4CA64C !important;
  border-bottom-left-radius: 15% !important;
  border-bottom: 1px solid #4CA64C !important;
}

[data-bs-theme="light"] .painel-pesquisa-desktop .border-painel-pesquisa-top {
  background-color: #CFF7DC !important; 
  border-top: 1px solid #4CA64C !important;
  border-bottom: 1px solid #4CA64C !important;
}

[data-bs-theme="light"] .painel-pesquisa-desktop .border-painel-pesquisa-right {
  background-color: #CFF7DC !important; 
  border-top-right-radius: 15% !important;
  border-top: 1px solid #4CA64C !important;
  border-right: 1px solid #4CA64C !important;
  border-bottom-right-radius: 15% !important;
  border-bottom: 1px solid #4CA64C !important;
}


[data-bs-theme="light"]  .painel-pesquisa-mobile .painel-pesquisa-action {
  flex-direction: column !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top: 3px solid #4CA64C !important;
  border-left: 3px solid #4CA64C !important;
  border-bottom: 3px solid #4CA64C !important;
}

[data-bs-theme="light"] .painel-pesquisa-mobile .painel-pesquisa-botao-ia {
  border: 4px solid #4CA64C !important;
}

[data-bs-theme="light"] .painel-pesquisa-mobile .border-painel-pesquisa-left {
  background-color: #CFF7DC !important; 
  border-top-left-radius: 15% !important;
}

[data-bs-theme="light"] .painel-pesquisa-mobile .border-painel-pesquisa-top {
  background-color: #CFF7DC !important; 
}

[data-bs-theme="light"] .painel-pesquisa-mobile .border-painel-pesquisa-right {
  background-color: #CFF7DC !important; 
  border-bottom-left-radius: 10% !important;
}

[data-bs-theme="dark"] .painel-pesquisa-mobile .painel-pesquisa-botao-ia {
  border-top-left-radius: 15% !important;
  border: 4px solid #4CA64C !important;
}

[data-bs-theme="dark"] .painel-pesquisa-mobile .border-painel-pesquisa-left {
  background-color: #4f9165 !important; 
  border-top-left-radius: 15% !important;
}

[data-bs-theme="dark"] .painel-pesquisa-mobile .border-painel-pesquisa-top {
  background-color: #4f9165 !important; 
}

[data-bs-theme="dark"] .painel-pesquisa-mobile .border-painel-pesquisa-right {
  background-color: #4f9165 !important; 
  border-bottom-left-radius: 10% !important;
}

[data-bs-theme="dark"] .painel-pesquisa-mobile .painel-pesquisa-action {
  flex-direction: column !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top: 3px solid #12381d !important;
  border-left: 3px solid #12381d !important;
  border-bottom: 3px solid #12381d !important;
}

.painel-pesquisa-mobile .painel-pesquisa-action .btn-sm {
  margin-top: 12px !important;
  margin-left: 12px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.painel-pesquisa-mobile .painel-pesquisa-ia {
  display: none !important;
}

.painel-pesquisa-action {
  display: flex !important;
  position: relative;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;  
  z-index: 12;
}

.painel-pesquisa-action{
  background-color:#4CA64C !important;
}

.painel-pesquisa-ia-head{
  position: relative !important;
  z-index: 11 !important;
  top: 13px !important;
  left: calc(50% - 108px) !important;
  height: 0px !important;
  width: 0px !important;
}

.painel-pesquisa-ia-head img{
  width: 220px;
  height: auto;
  z-index: 11 !important;
}

.painel-pesquisa-ia-right-hand{
  position: relative !important;
  z-index: 13 !important;
  top: 120.1px !important;
  right: 46px !important;
  height: 0px !important;
  width: 0px !important;
}

.painel-pesquisa-ia-right-hand-no-buttons{
  position: relative !important;
  z-index: 13 !important;
  top: 120.1px !important;
  right: 170px !important;
  height: 0px !important;
  width: 0px !important;
}

.painel-pesquisa-ia-right-hand img, .painel-pesquisa-ia-right-hand-no-buttons img{
  position: relative !important;
  z-index: 13 !important;
  width: 42px !important;
  height: auto !important;
}

.painel-pesquisa-ia-left-hand{
  position: relative !important;
  z-index: 13 !important;
  top: 120.1px !important;
  left: 75px !important;
  height: 0px !important;
  width: 0px !important;
}

.painel-pesquisa-ia-left-hand-no-buttons{
  position: relative !important;
  z-index: 13 !important;
  top: 120.1px !important;
  left: -50px !important;
  height: 0px !important;
  width: 0px !important;
}

.painel-pesquisa-ia-left-hand img, .painel-pesquisa-ia-left-hand-no-buttons img{
  position: relative !important;
  z-index: 13 !important;
  width: 42px !important;
  height: auto !important;
}

.painel-pesquisa-desktop .painel-pesquisa-false-button{
  display: block !important;
  width: 45px !important;
  height: 56.59px !important;
}

.painel-pesquisa-mobile .painel-pesquisa-false-button{
  display: none !important;
}

.textarea-texto-pesquisa {
  overflow: hidden !important; 
  overflow-wrap: break-word !important; 
  min-height: 110px !important;
  height: auto;
}

.textarea-pergunta-pesquisa {
  overflow: hidden !important; 
  overflow-wrap: break-word !important; 
  min-height: 60px !important;
  height: auto;
}

[data-bs-theme="light"] .color-pesquisa-drop-zone .dropzone {
  border-color: #0a6b25 !important;
  background-color: #A0E6B8 !important;
}

[data-bs-theme="dark"] .color-pesquisa-drop-zone .dropzone {
  border-color: #0a6b25 !important;
  background-color: #2a48318a !important;
}

[data-bs-theme="dark"] .color-pesquisa-drop-zone .dropzone .text-gray-400{
  color: #888888 !important;
}

.color-pesquisa-drop-zone .dropzone .bi-file-earmark-arrow-up {
  color: #0a6b25 !important;
}

.mat-horizontal-content-container .stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mat-horizontal-content-container  .stepper-item {
  position: relative;
  display: flex;
  align-items: center;
}


.mat-horizontal-content-container  .stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 1px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.mat-horizontal-content-container  .stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.mat-horizontal-content-container .stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
}
[data-bs-theme="light"] .color-white-step-number{
  color: #1c2f21 !important;
}

[data-bs-theme="dark"] .color-white-step-number{
  color: white !important;
}

.current .color-white-step-number{
  color: white !important;
}

[data-bs-theme="light"] .mat-horizontal-content-container .stepper-item .step-counter {
  background-color: #A0E6B8 !important;
}

[data-bs-theme="dark"] .mat-horizontal-content-container .stepper-item .step-counter {
  background-color: #1c2f21 !important;
}

.mat-horizontal-content-container .stepper-item.current .step-counter {
  background-color: #4CA64C !important;
}

.mat-horizontal-content-container .stepper-item.completed .step-counter {
  background-color: #4CA64C !important;
}

.mat-horizontal-content-container .completed .stepper-check {
  color: white !important;
}

.mat-horizontal-content-container  .stepper-item:first-child::before {
  content: none !important;
}
.mat-horizontal-content-container  .stepper-item:last-child::after {
  content: none !important;
}

[data-bs-theme="light"] .title_box {
  border: black 1px solid;
  border-radius: 5px;
  text-align:center;
}

[data-bs-theme="light"] .title_box #title {
  position: relative;
  top: -0.9em;
  display: inline;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

[data-bs-theme="dark"] .title_box {
  border: rgb(255, 255, 255) 1px solid;
  border-radius: 5px;
  text-align:center;
}

[data-bs-theme="dark"] .title_box #title {
  position: relative;
  top: -0.9em;
  display: inline;
  background-color: #1E1E2D;
  padding-left: 5px;
  padding-right: 5px;
}

[data-bs-theme="light"] .date-picker-pesquisa label{
  color: rgb(62, 62, 62);
}

[data-bs-theme="dark"] .date-picker-pesquisa label{
  color: rgb(187, 187, 187);
}

[data-bs-theme="light"] .card-pesquisa-nao-selecionado{
  background-color: #D2E9D2!important; 
  border-color: #4CA64C !important;
  cursor: pointer !important;
}

[data-bs-theme="dark"] .card-pesquisa-nao-selecionado{
  background-color: #1c2f21 !important;
  border-color: #44674e !important;
  cursor: pointer !important;
}

[data-bs-theme="light"] .card-pesquisa-selecionado{
  background-color: #a0e6b8 !important; 
  border-color: #4CA64C !important;
}

[data-bs-theme="dark"] .card-pesquisa-selecionado{
  background-color: #4c7f59 !important;
  border-color: #44674e !important;
}

[data-bs-theme="light"] .card-finalizar-color{
  background-color: #D2E9D2!important; 
}

[data-bs-theme="dark"] .card-finalizar-color{
  background-color: #1c2f21 !important;
}

[data-bs-theme="light"] .table-border-pesquisa{
  border: 2px solid #f1fff2 !important;
  border-radius: 5px !important;
}

[data-bs-theme="dark"] .table-border-pesquisa{
  border: 2px solid #0b6750 !important;
  border-radius: 5px !important;
}

[data-bs-theme="light"] .table-header-pesquisa{
  background-color: #f1fff2 !important;
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .table-header-pesquisa{
  background-color: #2148387c !important;
  color: #e4e4e4 !important;
}

[data-bs-theme="light"] .table-footer-pesquisa{
  background-color: #f1fff2 !important;
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .table-footer-pesquisa{
  background-color: #2148387c !important;
  color: #e4e4e4 !important;
}

[data-bs-theme="light"] .table-row-pesquisa{
  background-color: #fdfdfd !important;
}

[data-bs-theme="dark"] .table-row-pesquisa{
  background-color: #172624 !important;
}

[data-bs-theme="dark"] .tabela-pesquisa .Mui-active,.tabela-pesquisa .MuiTableSortLabel-iconDirectionAsc, .tabela-pesquisa .MuiTableSortLabel-iconDirectionDesc {
  color: #00f4b7 !important;
}

[data-bs-theme="dark"] .tabela-pesquisa .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  color: #7a957a !important;
}

[data-bs-theme="light"] .tabela-pesquisa .Mui-active,.tabela-pesquisa .MuiTableSortLabel-iconDirectionAsc, .tabela-pesquisa .MuiTableSortLabel-iconDirectionDesc {
  color: #1d1d1d !important;
}

[data-bs-theme="light"] .tabela-pesquisa .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  color: #8ba286 !important
}

.tabela-pesquisa.MuiPaper-root {
  background-color: #171b2600 !important;
}

.pesquisa-datalist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  writing-mode: vertical-lr;
}

.pesquisa-datalist option {
  writing-mode: horizontal-tb !important;
  padding: 0 !important;
  color: #4CA64C !important;
}

.pesquisa-datalist :first-child { 
  margin-left: 7px;
}

.pesquisa-input-range::-webkit-slider-runnable-track {
  height: 12px;
  cursor: pointer;
  background: #4CA64C;
}

.pesquisa-input-range::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #d1d1d1;
  cursor: pointer;
  margin-top: -4px;
}

.pesquisa-input-range-com-resposta::-webkit-slider-thumb {
  background: #d1d1d100 !important;
  border: 5px solid #d1d1d1 !important;
}

[data-bs-theme="dark"] .apexcharts-legend-text {
  color: #e5e5e5 !important;
}

[data-bs-theme="light"] .apexcharts-legend-text {
  color: #222222 !important
}

.grafico-media-por-dimensao-participante:hover{
  cursor: pointer !important;
  opacity: 0.6 !important;
}

[data-bs-theme="light"] .btn-painel-pesquisa .btn-icon i {
  color: #dfdede !important;
}