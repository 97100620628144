
body.swal2-height-auto {
  height: 100% !important;
}

body {
  height: auto !important;
}

.block-ui-container{
  z-index: 9999 !important;
}

.block-ui-overlay{
  background-color: black !important;
  opacity: 0.2 !important;
}

.loading-bullet{
  color: #f27545 !important;
}

[data-bs-theme="dark"] .swal2-popup{
  background: #1e1e2d !important;
}

.btn-accordion[aria-expanded="true"] i.fa-arrow-down {
  display: none;
}

.btn-accordion[aria-expanded="false"] i.fa-arrow-up {
  display: none;
}

[data-bs-theme="light"] .bg-mensagem-swal {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

[data-bs-theme="dark"] .bg-mensagem-swal {
  background-color: #1e1e2d;
  color: rgb(255, 255, 255);
}

.btn-pesquisa {
  background-color: #0B7328;
  color: white;
}

.btn-pesquisa:hover{
  background-color: #0a6b25;
  color: white;
}

.btn-pesquisa:disabled{
  opacity: 0.5;
  background-color: #0B7328;
  color: white;
}

.text-pesquisa{
  color: #0B7328;
}

textarea {
  resize: none;
}

.dropzone.is-invalid {
  border-color: var(--bs-danger) !important;
}

.dropzone.is-valid {
  border-color: var(--bs-success) !important;
}

.dropzone .dz-preview.dz-image-preview{
  background-color: rgba(190, 190, 190, 0.1) !important;
}

.dz-image{
  display: table-cell !important;
  vertical-align: middle !important;
  padding: 5px !important;
}

.dz-image img{
  width: 110px !important;
  height: auto !important;
  max-height: 110px !important;
}

/*Inicio css table */
[data-bs-theme="light"] .table-border{
  border: 2px solid #f1faff !important;
  border-radius: 5px !important;
}

[data-bs-theme="dark"] .table-border{
  border: 2px solid #212e48 !important;
  border-radius: 5px !important;
}

[data-bs-theme="light"] .table-header{
  background-color: #f1faff !important;
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .table-header{
  background-color: #212e48 !important;
  color: #e4e4e4 !important;
}

[data-bs-theme="light"] .table-footer{
  background-color: #f1faff !important;
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .table-footer{
  background-color: #212e48 !important;
  color: #e4e4e4 !important;
}

[data-bs-theme="light"] .table-row{
  background-color: #fdfdfd !important;
}

[data-bs-theme="dark"] .table-row{
  background-color: #171b26 !important;
}

[data-bs-theme="light"] .date-picker-color input{
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .date-picker-color input{
  color: #e4e4e4 !important;
}

.popover-filtro-color-bg .MuiPopover-paper {
  background-color: #171b2600 !important;
}

.popover-filtro{
  z-index: 105; 
  position: fixed; 
  inset: 0px 0px auto auto; 
  margin: 0px; 
  transform: translate3d(-104.533px, 353.6px, 0px);
}


[data-bs-theme="light"] .table-cell{
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .table-cell{
  color: #e4e4e4 !important;
}

[data-bs-theme="light"] .MuiSvgIcon-fontSizeMedium{
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .MuiSvgIcon-fontSizeMedium{
  color: #e4e4e4 !important;
}

[data-bs-theme="light"] .tabela-descricao .Mui-active,.tabela-descricao .MuiTableSortLabel-iconDirectionAsc, .tabela-descricao .MuiTableSortLabel-iconDirectionDesc {
  color: #000000 !important;
}

[data-bs-theme="light"] .tabela-descricao .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  color: #676767 !important;
}

[data-bs-theme="dark"] .tabela-descricao .Mui-active,.tabela-descricao .MuiTableSortLabel-iconDirectionAsc, .tabela-descricao .MuiTableSortLabel-iconDirectionDesc {
  color: #000000 !important;
}

[data-bs-theme="dark"] .tabela-descricao .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  color: #060606 !important;
}

.Mui-active, .MuiTableSortLabel-iconDirectionAsc, .MuiTableSortLabel-iconDirectionDesc {
  color: #5f82ce !important;
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  color: #5f82ce !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  margin-top: 10px !important;
}

.tabela-descricao.MuiPaper-root {
  background-color: #171b2600 !important;
}
/*Fim css table */

.icon-dashboard-arrow {
  font-size: 3em !important;
}

.accordion.accordion-icon-toggle .collapsed .accordion-icon {
  transition: all 0.2s ease-in-out !important;
  transform: rotate(0) !important;
}
.accordion.accordion-icon-toggle .accordion-icon {
  display: flex !important;
  flex-shrink: 0 !important;
  transition: all 0.2s ease-in-out !important;
  transform: rotate(-180deg) !important;
  align-items: center !important;
  justify-content: center !important;
  
}
.accordion-header.collapsed.hoverable  {
  border-radius: 5px 5px 5px 5px !important;
}

.accordion-header.hoverable {
  border-radius: 5px 5px 0px 0px !important;
}

.hover-link-name-dash:hover {
  opacity: 0.5;
}

[data-bs-theme="light"] .borda-aprimorar-color {
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

[data-bs-theme="dark"] .borda-aprimorar-color {
  border-style: solid;
  border-color: rgb(255, 255, 255);
}

[data-bs-theme="light"] .borda-tasks-aprimorar-color {
  border-style: dashed;
  border-color: rgb(74, 74, 74);
}

[data-bs-theme="dark"] .borda-tasks-aprimorar-color {
  border-style: dashed;
  border-color: rgb(187, 187, 187);
}

.borda-tasks-aprimorar-color:hover {
  opacity: 0.5;
}

.scroll-cards-aprimorar {
  overflow-y: scroll;
  height: 250px;
}

.wrapper-olho {
  position:relative;
}

.icon-olho {
  position: absolute !important;
  top:50% !important;
  right:20px;
  transform: translateY(-50%) !important;
}

.input-olho {
  box-sizing:border-box;
}

.icon-olho-distancia-maior {
  position: absolute !important;
  top:50% !important;
  right:45px;
  transform: translateY(-50%) !important;
}

.form-check-input:not(:checked) {
  background-color: var(--bs-gray-400) !important;
}

.controls {

  display: flex;
  align-items: center;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

body ::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
[data-bs-theme="light"] body ::-webkit-scrollbar-thumb {
  background-color: rgb(156, 155, 155) !important; 
}

/* Handle on hover */
[data-bs-theme="light"] body ::-webkit-scrollbar-thumb:hover {
  background-color: rgb(201, 200, 200) !important; 
}

[data-bs-theme="light"] body .--bs-scrollbar-color {
  background-color: rgb(201, 200, 200) !important; 
}

/* Handle */
[data-bs-theme="dark"] body ::-webkit-scrollbar-thumb {
  background-color: rgb(201, 200, 200) !important; 
}

/* Handle on hover */
[data-bs-theme="dark"] body ::-webkit-scrollbar-thumb:hover {
  background-color: rgb(156, 155, 155) !important; 
}

[data-bs-theme="dark"] body .--bs-scrollbar-color {
  background-color: rgb(156, 155, 155) !important; 
}

[data-bs-theme="dark"] .icon-button-dark-hover:hover {
  background-color: rgba(128, 128, 128, 0.33);
}

.draggable-false-image {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.input-color-field-class {
  padding: 0%;
}

.input-color-field-class, ::-moz-color-swatch,
::-moz-focus-inner{
  border: 0;
}