.btn-descricao {
  color: #ffffff !important;
  border-color: #334ea1 !important;
  background-color: #334ea1 !important;
}

.btn-descricao:hover {
  color: #ffffff !important;
  border-color: #3c56a3 !important;
  background-color: #3c56a3 !important;
}

.btn-light-descricao {
  color: #ffffff !important;
  border-color: #586eb0 !important;
  background-color: #586eb0 !important;
}

.btn-light-descricao:hover {
  color: #ffffff !important;
  border-color: #334ea1 !important;
  background-color: #334ea1 !important
}

.bg-descricao{
  background-color: #334ea1 !important;
}

.bg-light-descricao{
  background-color: #c3e0fa !important;
}

.border-descricao{
  border-color: #334ea1 !important;
}

.campo-descricao-selecionado{
  border: 1px solid #334ea1 !important;
}

.campo-descricao-interativo.campo-descricao-nao-selecionado .card-header{
  cursor: pointer !important;
}

.resposta-descricao{
  opacity: 0.8 !important;
}

[data-bs-theme="light"] .resposta-descricao{
  background-color: #e2effa !important;
  color: #2b2b2b !important;
}

[data-bs-theme="dark"] .resposta-descricao{
  background-color: #222e50 !important;
  color: #e4e4e4 !important;
}

.resposta-descricao-selecionada{
  opacity: 1 !important;
  border: 1px solid #334ea1 !important;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}

[data-bs-theme="light"] .resposta-descricao-selecionada{
  background-color: #c9e4fa !important; 
}

[data-bs-theme="dark"] .resposta-descricao-selecionada{
  background-color: #3d528b !important;
}

.resposta-descricao-nao-selecionada{
  cursor: pointer !important;
}

.painel-descricao-mobile{
  top: calc(50% - 204.19px) !important;
  left: calc(100% - 45.3px) !important;
}

.painel-descricao-desktop .painel-descricao-action {
  border-radius: 10px !important;
  border: 4px solid #283e7e !important;
}

[data-bs-theme="dark"] .painel-descricao-desktop .painel-descricao-botao-ia {
  border: 4px solid #283e7e !important;
}

[data-bs-theme="dark"] .painel-descricao-desktop .border-painel-descricao-left {
  background-color: #3d528b !important;
  border-top-left-radius: 15% !important;
  border-top: 1px solid #283e7e !important;
  border-bottom-left-radius: 15% !important;
  border-bottom: 1px solid #283e7e !important;
}

[data-bs-theme="dark"] .painel-descricao-desktop .border-painel-descricao-top {
  background-color: #3d528b !important;
  border-top: 1px solid #283e7e !important;
  border-bottom: 1px solid #283e7e !important;
}

[data-bs-theme="dark"] .painel-descricao-desktop .border-painel-descricao-right {
  background-color: #3d528b !important;
  border-top-right-radius: 15% !important;
  border-top: 1px solid #283e7e !important;
  border-right: 1px solid #283e7e !important;
  border-bottom-right-radius: 15% !important;
  border-bottom: 1px solid #283e7e !important;
}

[data-bs-theme="light"] .painel-descricao-desktop .painel-descricao-botao-ia {
  border: 4px solid #283e7e !important;
}

[data-bs-theme="light"] .painel-descricao-desktop .border-painel-descricao-left {
  background-color: #c9e4fa !important; 
  border-top-left-radius: 15% !important;
  border-top: 1px solid #283e7e !important;
  border-bottom-left-radius: 15% !important;
  border-bottom: 1px solid #283e7e !important;
}

[data-bs-theme="light"] .painel-descricao-desktop .border-painel-descricao-top {
  background-color: #c9e4fa !important; 
  border-top: 1px solid #283e7e !important;
  border-bottom: 1px solid #283e7e !important;
}

[data-bs-theme="light"] .painel-descricao-desktop .border-painel-descricao-right {
  background-color: #c9e4fa !important; 
  border-top-right-radius: 15% !important;
  border-top: 1px solid #283e7e !important;
  border-right: 1px solid #283e7e !important;
  border-bottom-right-radius: 15% !important;
  border-bottom: 1px solid #283e7e !important;
}

.painel-descricao-mobile .painel-descricao-action {
  flex-direction: column !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top: 3px solid #283e7e !important;
  border-left: 3px solid #283e7e !important;
  border-bottom: 3px solid #283e7e !important;
}

[data-bs-theme="light"] .painel-descricao-mobile .painel-descricao-botao-ia {
  border: 4px solid #283e7e !important;
}

[data-bs-theme="light"] .painel-descricao-mobile .border-painel-descricao-left {
  background-color: #c9e4fa !important; 
  border-top-left-radius: 15% !important;
}

[data-bs-theme="light"] .painel-descricao-mobile .border-painel-descricao-top {
  background-color: #c9e4fa !important; 
}

[data-bs-theme="light"] .painel-descricao-mobile .border-painel-descricao-right {
  background-color: #c9e4fa !important; 
  border-bottom-left-radius: 10% !important;
}

[data-bs-theme="dark"] .painel-descricao-mobile .painel-descricao-botao-ia {
  border-top-left-radius: 15% !important;
  border: 4px solid #283e7e !important;
}

[data-bs-theme="dark"] .painel-descricao-mobile .border-painel-descricao-left {
  background-color: #3d528b !important; 
  border-top-left-radius: 15% !important;
}

[data-bs-theme="dark"] .painel-descricao-mobile .border-painel-descricao-top {
  background-color: #3d528b !important; 
}

[data-bs-theme="dark"] .painel-descricao-mobile .border-painel-descricao-right {
  background-color: #3d528b !important; 
  border-bottom-left-radius: 10% !important;
}

.painel-descricao-mobile .painel-descricao-action .btn-sm {
  margin-top: 12px !important;
  margin-left: 12px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.painel-descricao-mobile .painel-descricao-ia {
  display: none !important;
}

.painel-descricao-action {
  display: flex !important;
  position: relative;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;  
  z-index: 12;
}

.painel-descricao-action{
  background-color: #283e7e !important;
}

.painel-descricao-ia-head{
  position: relative !important;
  z-index: 11 !important;
  top: 13px !important;
  left: calc(50% - 108px) !important;
  height: 0px !important;
  width: 0px !important;
}

.painel-descricao-ia-head img{
  width: 220px;
  height: auto;
  z-index: 11 !important;
}

.painel-descricao-ia-right-hand{
  position: relative !important;
  z-index: 13 !important;
  top: 120.1px !important;
  right: 46px !important;
  height: 0px !important;
  width: 0px !important;
}

.painel-descricao-ia-right-hand img{
  position: relative !important;
  z-index: 13 !important;
  width: 42px !important;
  height: auto !important;
}

.painel-descricao-ia-left-hand{
  position: relative !important;
  z-index: 13 !important;
  top: 120.1px !important;
  left: 75px !important;
  height: 0px !important;
  width: 0px !important;
}

.painel-descricao-ia-left-hand img{
  position: relative !important;
  z-index: 13 !important;
  width: 42px !important;
  height: auto !important;
}

.painel-descricao-desktop .painel-descricao-false-button{
  display: block !important;
  width: 45px !important;
  height: 56.59px !important;
}

.painel-descricao-mobile .painel-descricao-false-button{
  display: none !important;
}

.textarea-resposta-descricao {
  overflow: hidden !important; 
  overflow-wrap: break-word !important; 
  min-height: 90px !important;
  height: auto;
}